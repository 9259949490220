@import "../../variables";

.GenericItem {
    .HorizontalDivider {
        margin: 24px 0 0 0;
    }
    .Button-goto {
        margin: 0;
        padding: 0;
        min-width: $marginXLarge;
        min-height: unset;
    }
}