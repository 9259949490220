@import "../../variables";

.DocumentItem {
    border: 1px solid $colorLightGray;
    border-radius: $baseSpacing;
    margin: $baseSpacing 0;
    padding: 0 $marginNormal;

    > .DocumentItem-section {
        position: relative;
        display: inline-block;
        margin: 0 $marginXSmall;
        line-height: 60px;
        &.width-a {
            min-width: 25%;
        }
        &.float-right {
            float: right;
        }
        .status-unsigned {
            color: $colorWarning;
        }
        .clickable-icon {
            cursor: pointer;
            margin-left: $marginXSmall;
            &:hover {
                background-color: $backgroundDarker;
            }
        }
        .color-text {
            color: $colorText;
        }
    }
}
