@import "../../variables";

.Overview-wrapper {
  padding: 32px;

  .date {
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.2px;
    margin-bottom: $marginNormal;
    color: #9C9FAF;
  }
      
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #000000;
    margin-top: 8px;
    margin-bottom: 12px;
    white-space: nowrap;
  }

  .lists-grid {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    gap: $marginLarge;
    height: 55vh;

    .grid-member {
      flex: 1;
      height: 100%;

      .BasicCard {
        display: flex;
        flex: 2;
        flex-direction: column;
        height: 100%;
      }
    }

    @media screen and (max-width: $breakpointLg+px){
      flex-direction: column;
    }

    .TasksViewer {
      max-height: 100%;
    }
  }
}