@import "../../variables";

.SingleEntityLayout {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;

    .SingleEntityLayout-leftpanel {
        width: 300px;
        min-width: 300px;
        border-left: 2px solid #f5f6f8;
    }

    .SingleEntityLayout-right {
        flex: 1;
        background-color: $backgroundDarker;
    }

    @media screen and (max-width: $breakpointMd+px) {
        flex-direction: column;
        .SingleEntityLayout-leftpanel {
            width: unset;
        }
    }
}