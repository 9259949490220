@import '../../variables';

.RecentActivityItem {
    .HorizontalDivider {
        margin: 24px 0 0 0;
    }

    .RecentActivityItem-creator {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .RecentActivityItem-text {
        margin-top: $marginXSmall;
        padding-right: $marginNormal;
    }

    .RecentActivityItem-goto {
        margin: 0;
        padding: 0;
        min-width: $marginXLarge;
        min-height: unset;
    }

    .RecentActivityItem-divider {
        padding: 0;
        border: none;
        border-top: 1px solid $colorLightGray;
    }

    &:last-child .RecentActivityItem-divider {
        display: none;
    }
}