@import "../../variables";

.ProgressIndicator {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $marginNormal;
    &.stretched{
        height: 100%;
        box-sizing: border-box;
    }
}