@import "../../variables";

.TabbedNavigation {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;

    width: 100%;
    height: 45px;
    background-color: #FFFFFF;

    .TabbedNavigation-tab {
        margin: 0 $marginNormal;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid transparent;

        cursor: pointer;

        color: $colorLightGray;
        font-weight: 400;
        &.is-active {
            color: $colorPrimary;
            border-bottom: 1px solid $colorPrimary;
        }
    }

    @media screen and (max-width: $breakpointSm+px) {
        .TabbedNavigation-tab {
            margin: 0 $marginSmall;
        }
    }
}