@import "../../variables";

.SingleNotePreview {
    padding: $marginSmall 0;
    margin: 0 $marginSmall;
    cursor: pointer;

    &.SingleNotePreview-list {
        border-bottom: 1px solid $colorLightGray;
        &:last-child {
            border-bottom: none;
        }
    }

    &.SingleNotePreview-bricks {
        display: flex;
        flex-direction: column;
        .content {
            flex: 1;
        }
        border-top: 1px solid $colorLightGray;
        &:nth-child(-n+3) {
            border-top: none;
            padding-top: 0;
        }
        @media screen and (max-width: $breakpointLg+px){
            &:nth-child(-n+3) {
                border-top: 1px solid $colorLightGray;
                padding-top: $marginSmall;
            }
            &:nth-child(-n+2) {
                border-top: none;
                padding-top: 0;
            }
        }
        @media screen and (max-width: $breakpointMd+px){
            &:nth-child(-n+2) {
                border-top: 1px solid $colorLightGray;
                padding-top: $marginSmall;
            }
            &:nth-child(-n+3) {
                border-top: none;
                padding-top: 0;
            }
        }
    }
}