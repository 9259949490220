@import "../../variables";

.UserEdit {
    padding: $marginLarge;

    .with-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .breadcrumb-navigation {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: flex-start;

        margin-bottom: $marginXLarge;

        cursor: pointer;

        .arrow-back {
            width: 1.8em;
            margin-right: 1em;
            background-image: url('../../assets/arrow_back.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }

    .merge-button {
        font-size: 14px;
        margin-left: $marginSmall;
        margin-bottom: $marginLarge;
    }

    .EditField.bottom-spacing {
        padding-bottom: $marginXSmall;
    }
}