@import '../../variables';

.RecentDocumentsModal {

    .modal-title {
        margin-bottom: $marginNormal;
    }

    .modal-content {
        width: 70vh;
    }

    .modal-footer {
        width: 75vh;
        margin: $marginNormal auto;
        text-align: left;
        align-self: flex-start;
    }
}

