@use "../../variables" as var;

.ProjectsPage {

}

.controlBar {
    margin: var.$marginNormal;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: var.$breakpointXl+px) {
        flex-direction: column-reverse;
    }
}
