@import "../../../variables";

.CardProjectPurge {
    margin: $marginSmall;
    .BasicCard-header {
        margin-bottom: $marginLarge;
    }
}

.CardProjectPurge-confirmation-modal {
    .error-feedback {
        color: $colorDanger;
    }
}
