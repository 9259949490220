@import '../../variables';

.DocumentItemOnDashboard {

    border: 1px solid $colorLightGray;
    padding: $marginLarge;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;

    overflow: hidden;

    margin-bottom: 8px;
    &:last-child {
        margin-bottom: 0;
    }

    .DocumentItemOnDashboard-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .DocumentItemOnDashboard-section-main {
            flex: 1;
            line-height: 1;
        }

        .RecentActivityItem-goto {
            margin: 0;
            padding: 0;
            min-width: $marginXLarge;
            min-height: unset;
        }

        .bullet-point {
            margin: 0 $marginXSmall;
            white-space: normal;
        }

        .DocumentItemOnDashboard-status-narrow {
            display: none;
        }
    }

    @media screen and (max-width: $breakpointSm+px) {
        .DocumentItemOnDashboard-inner {
            .DocumentItemOnDashboard-status-narrow {
                display: block;
            }
            .DocumentItemOnDashboard-status-wide {
                display: none;
            }
            .document-filename {
                font-weight: 500;
            }
            .document-metadata {
                display: flex;
                flex-direction: column;
                > span {
                    margin-top: $marginXSmall;
                }
                .bullet-point {
                    display: none;
                }
            }
        }
    }
}