@import "../../variables";

.AddDocumentsModal {

  .ModalDialog-inner {
    min-height: 80vh;
    height: 100%;
  }

  .modal-contents-wrapper {
      height: 100%;
  }
  
  .modal-title {
      text-align: center;
      margin-bottom: 8px;
  }

  .modal-subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #252733;
    margin-bottom: 24px;
    max-width: $breakpointSm+px;
  }

  .EditField {
    padding: 0;
  }

  .upload-label {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.2px;
    color: #000000;
    margin-top: 12px;
    margin-bottom: 24px;
  }

  .actions {
    text-align: center;
  }
  
  .button-send-invite {
      width: 400px;
      margin-top: 16px;
      margin-bottom: 32px;
  }

  .errorMessage {
      padding: $marginSmall;
      text-align: center;
  }
}