
@import "../../variables";

.drag-and-drop {
  width: 400px;
  margin: 0 auto;

  .input-label {
    margin-bottom: 8px;
    font-size: 14px;
  }

  .drag-and-drop-input {
    position: relative;
    width: 100%;
    height: 214px;
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 0;
    border-radius: 3px;
  
    &:before {
      content: "";
      position: absolute;
      border: 5px dashed $colorPrimary;
      top: -4px;
      bottom: -4px;
      left: -4px;
      right: -4px;
      z-index: 1;
      pointer-events: none;
    }

    &.red-border:before {
      border-color: #D21C1C;
    }
  
    .drop-active-overlay {
      position: absolute;
      background: rgba(0, 0, 0, 0.6);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      z-index: 3;
    }
  
    .browse-button {
      color: #139CFF;
      z-index: 2;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  
      .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }

      .text {
        text-align: center;
        font-size: 16px;
        line-height: 19px;
        margin: 16px 0 12px;
        color: #000;
      }
  
      label {
        cursor: pointer;
        border: 1px solid $colorPrimary;
        border-radius: 6px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        padding: 8px 32px;
      }
    }
  
    .after-upload-features {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 12px;
  
      .left {
        display: flex;
        align-items: center;
  
        .file-preview {
          height: 64px;
          width: auto;
          max-width: 128px;
          object-fit: cover;
        }
  
        .file-data {
  
          .file-name {
            max-width: 100px;
            word-break: break-all;
          }
  
          .file-dimensions {
            margin-top: 4px;
            font-size: 12px;
            color: #86888C;
          }
        }
      }
  
      .right {
        display: flex;
        align-items: center;
  
        label {
          display: flex;
          align-items: center;
        }
  
        .refresh-icon {
          cursor: pointer;
        }
  
        .close-icon {
          cursor: pointer;
        }
      }
    }
  }

  .files-list {
    margin-top: 32px;
    
    .file {
      margin-bottom: 12px;
      padding: 16px 24px;
      border: 1px solid #D8D9DF;
      border-radius: 7px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        align-items: center;

        .file-data {
          margin-left: 16px;

          .file-name {
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
          }

          .file-status {
            margin-top: 4px;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            color: #9C9FAF;
          }
        }
      }

      .right {
        img {
          cursor: pointer;
        }
      }
    }
  }

  .input-error {
    margin-top: 4px;
    color: #ED143D;
    font-size: 14px;
  }
}