@use "../../../../variables" as var;

.filterWrapper {
    padding: var.$marginSmall;
}

.filterWrapperInner {
    width: 305px;
}

.filterShadow {
    box-shadow: 1px 1px 2px 0 #A5ABB6;
}

.inputText {

}

.filter {
    width: 100%;
}

.noPadding {
    padding: 0;
}

.inputHeight {
    height: 55px;
}

.PersonalFilter {
    padding: var.$marginSmall;
    max-width: 220px;
    width: 220px;
}
