@import "../../variables";

.MergeCompaniesModal {

    .modal-title {
        margin-bottom: $marginLarge;
    }
    .modal-content {
        margin-bottom: $marginLarge;
        &:last-child {
            margin-bottom: 0;
        }
        align-self: stretch;
    }

    .warning {
        color: $colorDanger;
    }

    .decision-title {
        margin-bottom: $marginLarge;
    }

    .company-selection {
        margin-top: $marginSmall;
        margin-bottom: $marginLarge;
        display: flex;
        flex-direction: row;
        .companies-dropdown-autocomplete {
            flex: 1;
        }
        .merge-action {
        }
    }
    .lighter {
        font-weight: 400;
    }
    .stronger {
        font-weight: 500;
    }
}