@import "../../variables";

.TotpConfigurationModal {

    .section-title {
        margin-bottom: $marginNormal;
    }

    .qr-code-container {
        text-align: center;
        padding: $marginSmall;
    }

    .section-actions {
        width: 100%;
        margin-top: $marginNormal;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}