@import "../../variables";

.AllActivitiesModal {

    .ModalDialog-inner {
        background-color: $backgroundLighter;
    }

    .modal-title {
        text-align: center;
    }

    .modal-content {
        width: 90%;
    }

    .modal-footer {
        text-align: left;
        align-self: stretch;
    }
}