@import "../../variables";

.CardUserPurge {
    margin: $marginSmall;
    .BasicCard-header {
        margin-bottom: $marginLarge;
    }
}

.CardUserPurge-confirmation-modal {
    .error-feedback {
        color: $colorDanger;
    }
}