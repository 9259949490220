@use "../../../../variables" as var;

.CompanyType {
    margin: var.$marginSmall;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;

    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.details {
    margin-left: var.$marginLarge;
}

.actions {
}

.spacer {
    flex: 1;
}


.bold {
    font-weight: bold;
}
