@use "../../variables" as var;

$logoHeight: 66;

.LoginLayout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
}

.section {

    .sectionContent {
        max-width: var.$breakpointLg+px;
        padding: 0 var.$marginNormal;
        margin: 0 auto;
    }

    &.sectionMenu {
        background-color: var.$colorPrimary;
        height: var.$topBarHeight;

        .logo {
            margin-top: (var.$topBarHeight - $logoHeight)/2;
            height: $logoHeight+px;
        }

        .phoneArrow {
            position: absolute;
            top: -100%;
            right: var.$marginContents;
            transition: transform 1s, opacity 0.5s, top 1s;
            opacity: 0;
            z-index: 1;
            &.phoneArrowOpened {
                top: 0;
                opacity: 1;
                &:hover {
                    transform: translateY(-10px);
                }
            }
        }
    }

    &.sectionWhite {
        flex: 1;
    }

    &.sectionAddresses {
        background-color: var.$colorPrimary;
    }

    &.sectionFooter {
        background-color: darken(var.$colorPrimary, 5%);
        .sectionContent {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            height: var.$topBarHeight/2;
        }
    }
}

.sectionPayload {
    background-image: url(../../assets/login-image.jpg);
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    position: relative;
    min-height: 400px;
    .sectionContent {
        .shiftLeft {
            margin: var.$marginLarger 35% var.$marginLarger var.$marginLarger;
            position:relative;
            z-index: 1;
            color: var.$colorTextWhite;
        }
    }
    .transparentColored {
        position: absolute;
        background-color: var.$colorPrimary;
        opacity: 0.7;
        height: 100%;
        width: 100vw;
        transform: translateX(-30%) skewX(-30deg);
    }
}

.addressesPanel {
    display: grid;
    padding: var.$marginXLarge*2 var.$marginNormal;
    gap: var.$marginXLarge;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;

    @media screen and (max-width: var.$breakpointLg+px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }

    @media screen and (max-width: var.$breakpointSm+px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
    }
}

.softnautsSign {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var.$marginXSmall;

    .link {
        text-decoration: none;
        .linkText {
            color: var.$colorTextWhite;
        }
    }
}