@import "../../variables";

.LeftMenu {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    height: 100%;

    position: relative;

    .LeftMenu-platform {
        height: $topBarHeight;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 10px;
        width: 100%;

        .LeftMenu-toggle {
            display: none;
            cursor: pointer;
        }

        .LeftMenu-company {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding-left: $marginNormal;

            .LeftMenu-logo {
                height: $marginXLarge;
                width: 100%;
                background-image: url('../../assets/sf_logo_inverted.png');
                background-size: contain;
                background-position: left center;
                background-repeat: no-repeat;
            }
        }
    }

    @media screen and (max-width: $breakpointMd+px) {
        align-items: stretch;
        .LeftMenu-platform {
            .LeftMenu-toggle {
                display: block;
                margin-right: $marginNormal;
            }
        }
        .LeftMenu-items {
            overflow: hidden;
            transition: height 0.3s ease-out;
            &.is-opened {
                height: 100%;
            }
            &.is-closed {
                height: 0;
            }
        }
    }

    @media screen and (max-width: $breakpointMd+px) {
        .LeftMenu-platform .LeftMenu-company {
            display: none;
        }
    }
}