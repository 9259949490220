@import "../../variables";

.UnseenNotificationsDialog {

    .UnseenNotificationsDialog-paper {
        position: absolute;
        top: $topBarHeight;
        right: $marginXLarge;
        min-width: $marginXLarge*10;

        .UnseenNotificationsDialog-triangle {
            position: absolute;
            right: $marginXLarge*5;
            z-index: -1;
            background-color: $backgroundLighter;
            height: $marginXLarge;
            width: $marginXLarge;
            transform: translateY(-50%) rotate(45deg) ;
        }

        .UnseenNotificationsDialog-inner {
            max-height: 80vh;
            overflow: auto;
        }
    }
}