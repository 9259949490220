@import "../../variables";

.NavBar {
    height: $topBarHeight;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    background-color: transparent;

    .NavBar-spacer {
        flex: 1;
    }

    .NavBar-element  {
        margin: $marginSmall;
        &.NavBar-action {
            margin-left: $marginXLarge;
        }
        &.NavBar-logout {
            cursor: pointer;
        }
        &.NavBar-userprofile {
            font-size: 16px;
            font-weight: 500;
            padding-bottom: 8px;
        }
    }

    .NavBar-notifications {
        position: relative;
        .NavBar-notifications-overlay {
            position: absolute;
            top: -40%;
            right: -40%;
            color: $colorDanger;
        }
        
        &.has-unread {
            cursor: pointer;
        }
    }

    .NavBar-notifications-red-dot {
        font-size: 0.75em;
        padding: 0.25em;
        font-weight: 500;
        height: 2em;
        width: 2em;
        line-height: 1.5em;
        text-align: center;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background-color: $colorDanger;
        color: $backgroundLighter;
    }

    @media screen and (max-width: $breakpointMd+px) {
        position: absolute;
        top: 0;
        right: 0;
    }
}