@import "../../variables";

.TaskModal {
    .ModalDialog-inner {
        background-color: $backgroundLighter;
    }

    .modal-contents-wrapper {
        width: 90%;
        height: 90%;
        top: 48px;

        .modal-content {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;

            .referItem {
                width: 170px;
                overflow: hidden;
                text-overflow: ellipsis;
                height: 50px;
            }
        }

        .modal-title {
            text-align: center;
        }

        .task-title {
            padding: 12px 0 0 0;
        }

        .task-description {
            padding: 24px 0 0 0;
        }

        .task-user,
        .task-document,
        .task-note,
        .task-task,
        .task-company,
        .task-project,
        .task-executor,
        .task-deadline,
        .task-completed {
            font-size: 16px;
            margin: -12px;
            width: 200px;
        }

        .EditField {
            a {
                text-decoration: none;
                color: $colorPrimary;
            }
        }

        .AddRefer {
            .EditField {
                width: 200px;
                padding-left: 0;
            }

            .MuiAutocomplete-inputRoot {
                height: 51.5px;
            }
        }
    }

    .ModalDialog-inner {
        position: absolute;
        min-height: 90%;
        width: 100%;
    }
}