@import "../../variables";

.UserProjects {
  .IndexPageHeader {
    margin-top: 0;
    margin-left: 32px;
  }

  .filters {
    margin: 32px;
    border-bottom: 1px solid #EAEAEA;
    display: flex;

    .filter {
        margin-right: 32px;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.2px;
        color: #AFB8C7;
        padding-bottom: 4px;

        &.filter-active {
            color: $colorPrimary;
            border-bottom: 2px solid $colorPrimary;
        }
    }
  }

  .leads-grid {
    margin: 24px 32px 32px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;

    .lead-box {
      background: #fff;
      padding: 24px;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.03);
      border-radius: 6px;

        .lead-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          .lead-header-left {
            display: flex;
            align-items: center;

            span {
              margin-left: 10px;
              font-weight: normal;
              font-size: 14px;
              line-height: 16px;
              letter-spacing: 0.2px;
              color: #9C9FAF;
            }
          }
        }

      .lead-name {
        margin-top: 16px;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.2px;
        color: #000000;
      }

      .lead-status {
        margin-top: 4px;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.2px;
        color: #000000;
      }

      .lead-primary-user {
        margin-top: 16px;
        display: flex;
        align-items: center;

        span {
          margin-left: 10px;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.2px;
          color: #9C9FAF;
        }
      }

      .lead-date {
        margin-top: 8px;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #9C9FAF;
      }

      .lead-visibility {
        margin-top: 20px;

        .MuiFormControlLabel-label {
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.2px;
          color: #252733;
          user-select: none;
        }
      }
    }
  }
}