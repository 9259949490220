@import "../../variables";

.forgotten-password-form {
  margin: 0 auto;
  max-width: 500px;

  @media screen and (max-width: $breakpointSm+px) {
    margin: 20px;
  }

  .title {
    font-size: 36px;
    font-weight: 500;
    text-align: left;
    color: $colorTextWhite;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  .content {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    text-align: left;
    margin-top: 16px;
  }

  .field-title {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: $colorTextWhite;
    margin-bottom: 8px;
    margin-top: 32px;
  }

  .cancel {
    line-height: 1.75;
    margin: auto;
    a {
      font-size: 16px;
      font-weight: 500;
      margin: auto;
      text-decoration: none;
      vertical-align: middle;

      color: $colorTextWhite;
      a {
        text-decoration: none;
        color: $colorTextWhite;
      }

      @media screen and ($breakpointSm+px) {
        font-size: 14px;
      }
    }
  }

  .on-dark-background {
    background-color: $backgroundLighter;
  }

  .btn {
    padding: 0.5em 2em;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: $colorPrimary;
    transition: background-color 0.5s;
    &:hover {
      background-color: lighten($colorPrimary, 30%);
    }
  }
}
