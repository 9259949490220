@use "../../../variables" as var;

.GroupingBlock {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;

    width: 100%;
    height: 45px;
    background-color: #FFFFFF;

    .GroupingBlockTab {
        margin: 0 var.$marginNormal;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid transparent;

        cursor: pointer;

        color: var.$colorLightGray;
        font-weight: 400;
        &.isActive {
            color: var.$colorPrimary;
            border-bottom: 1px solid var.$colorPrimary;
        }
    }

    @media screen and (max-width: var.$breakpointSm+px) {
        .GroupingBlockTab {
            margin: 0 var.$marginSmall;
        }
    }
}
