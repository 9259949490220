@import "../../variables";

.NotesPanel {

    &.NotesPanel-list {

    }

    &.NotesPanel-bricks {
        .NotesPanel-body {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            @media screen and (max-width: $breakpointLg+px){
                grid-template-columns: 1fr 1fr;
            }
            @media screen and (max-width: $breakpointMd+px){
                grid-template-columns: 1fr 1fr 1fr;
            }
        }


    }
}
