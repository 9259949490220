@import '../../variables';

.TasksFilterAndSort {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.EditField {
  flex: 1;
  padding: 8px 2px;

  @media screen and (max-width: $breakpointLg+px) {
    padding: 4px;
  }
}
