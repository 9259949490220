@use "../../variables" as var;

.TasksPage {

}

.pageTitle {
    margin: var.$marginXXLarge var.$marginLarger var.$marginLarge;
}

.controlBar {
    margin: var.$marginNormal;
    display: flex;
    flex-direction: row;
    gap: var.$marginSmall;

    @media screen and (max-width: var.$breakpointXl+px) {
        flex-direction: column-reverse;
    }
}
