@use "../../../variables" as var;

.TitleButtons {
    display: flex;
    align-items: baseline;
}


.button {
    padding: 0 15px;
    border-color: #D9DAE1;

    &:hover {
        background-color: #D9DAE1;
        border-color: #D9DAE1;
    }

    &.activeButton {
        background-color: #D9DAE1;
        border-color: #D9DAE1;
    }
}

.spacer {
    flex: 1;
}
