@import "../../variables";

.SingleAddressPanel {

    color: $colorTextWhite;

    .SingleAddressPanel-title {
        margin-bottom: $marginSmall;
    }

    .link {
        text-decoration: none;
        .link-text {
            color: $colorTextWhite;
        }
    }
}