@import "../../variables";

.TasksViewer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 235px;

    @media screen and (max-width: $breakpointLg+px) {
        width: 100%;
        margin: 5px 0;
    }
}