.AddingTask {
    .Title {
        font-size: 28px;
    }

    .DeadlineWrapper {
        display: flex;
        width: 70%;
        min-width: 630px;
        justify-content: space-between;
        margin: 0 12px;

        .EditField {
            padding: 0;
        }
    }

    .ReferType {
        width: 200px;
    }

    .Refer {
        width: 300px;

        .MuiAutocomplete-inputRoot {
            height: 51px;
        }
    }

    .MuiMobileStepper-root {
        width: calc(100% + 34px);
        box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.05);

        button {
            width: 150px;
        }

        .RightButton {
            margin-right: 48px;
        }
    }

    .modal-content {
        display: grid;
        grid-template: "a a a"
                       "b b c"
                       "b b c";
    }
}