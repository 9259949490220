@import "../../variables";

.AllNotesModal {

    .ModalDialog-inner {
        background-color: $backgroundLighter;
    }

    .modal-title {
        text-align: center;
    }

    .modal-content {
        width: 90%;

        .MuiCard-root {
            cursor: pointer;
        }
    }
    
}