@import '../../variables';

.RelatedProjectItem {
    cursor: pointer;

    .HorizontalDivider {
        margin: 24px 0 0 0;
    }

    .RelatedProjectItem-handler, .RelatedProjectItem-client {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        color: $colorTextGray;
        .user-display {
            flex: 1;
            padding-left: $marginXSmall;
        }
    }

    .RelatedProjectItem-handler {
        margin-bottom: $marginXSmall;
    }

    .RelatedProjectItem-client {
        margin-top: $marginXSmall;
    }

    .RelatedProjectItem-time {
        color: $colorTextGray;
    }
}