@import "../../variables";

.ProjectStatusBanner {
    margin: $marginSmall;

    .ProjectStatusBanner-inner {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .banner {
            flex: 1;
        }
        .MoreButton {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .banner {
        border: 1px solid transparent;
        font-size: 18px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;

        .banner-left {
            font-weight: 500;
            padding: $marginXSmall $marginSmall 0;
            border-top-right-radius: $marginXSmall;
            border-top-left-radius: $marginXSmall;
            background-color: lighten($colorPrimary, 50%);
        }
        .banner-right {
            font-weight: 400;
            padding: 0 $marginSmall $marginXSmall;
            border-bottom-right-radius: $marginXSmall;
            border-bottom-left-radius: $marginXSmall;
            background-color: lighten($colorPrimary, 50%);
        }
    }

    &.status-lead-rejected, &.status-opportunity-resigned {
        .banner .banner-left, .banner .banner-right {
            background-color: $colorGray;
        }
    }
}