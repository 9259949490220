@import "../../variables";

.NoteModal {
    .ModalDialog-inner {
        background-color: $backgroundLighter;
    }

    .modal-contents-wrapper {
        overflow: auto;
        width: 100%;

        .modal-content {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
        }

        .modal-title {
            text-align: center;
        }

        .note-text {
            flex: 1;
            padding-left: 0;

            .EditFieldStringTextArea {
                height: 100%;
                overflow: auto;
            }
        }
    }
}