@import "../../variables";

.NotificationsPage {

    max-width: 700px;

    .NotificationsPage-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: $marginXXLarge $marginLarger 0 $marginLarger;

        .mark-all-as-read {
            //font-size: ;
        }
    }

    .NotificationsPage-content {
        padding: $marginLarger $marginLarger 0 $marginLarger;
    }
}