@import "../../variables";

.Search-wrapper {
  thead tr th:first-child,
  thead tr th:nth-child(1) {
    display: none;
  }

  tbody tr td:first-child,
  tbody tr td:nth-child(2) {
    display: none;
  }
}

.Search {
  margin-left: 32px;
}