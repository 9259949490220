@use "../../../variables" as var;

.ProjectEdit {
    padding: var.$marginLarge;
}

.mergeButton {
    font-size: 14px;
    margin-left: var.$marginSmall;
    margin-bottom: var.$marginSmall;
}
