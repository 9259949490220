@use "../../../../variables" as var;

.NoteModal {

}
.metadata {
    width: 100%;
}

.textField {
    margin-top: var.$marginNormal;
    width: 100%;
}

.actions {
    width: 100%;
}

.spacer {
    flex: 1;
}
