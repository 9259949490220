@import "../../variables";

.ConfirmationDialog {
    .ConfirmationDialog-inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 44vw;
        min-height: 28vh;
        max-height: 85vh;
        overflow: auto;
        background-color: $backgroundDarker;
        border: 1px solid $colorPrimary;
        padding: $marginNormal;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .ConfirmationDialog-icon {
            height: 48px;
            width: 48px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }

        .ConfirmationDialog-button {
            margin-top: $marginXLarge;
        }
    }
}