@import "../../variables";

.Search {
    width: 305px;

    .Search-input {
        font-size: 14px;
        font-weight: 400;
        background-color: #FFFFFF;
        width: 100%;
    }

    @media screen and (max-width: $breakpointSm+px) {
        width: unset;
        max-width: 200px;
    }
}