@import "../../variables";

.create-new-password-form {
  margin: 0 auto;
  max-width: 500px;

  @media screen and (max-width: 460px) {
    margin: 20px;
  }

  input::placeholder {
    color: #9C9FAF;
    opacity: 1;
  }

  .title {
    font-size: 36px;
    font-weight: 500;
    text-align: left;
    color: $colorTextWhite;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  .field-title {
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    color: $colorTextWhite;
    margin-bottom: 8px;
    margin-top: 20px;
  }

  .forgotten-password {
    line-height: 1.75;
    a {
      font-size: 16px;
      font-weight: 500;
      margin: auto;
      text-decoration: none;
      vertical-align: middle;
      color: #000000;
    }
  }

  .on-dark-background {
    background-color: $backgroundLighter;
  }
}

.infoIcon {
  display: block;
  margin: 48px auto 0;
}
