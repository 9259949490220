@import "../../variables";

.CompanyTypeDot {
    font-size: 0.5em;
    line-height: 1;
    position: relative;
    margin-right: 0.5em;
    display: inline-block;

    &.company-type-client-personal {
        color: $colorClientPersonal;
    }
    &.company-type-client-company {
        color: $colorClientCompany;
    }
    &.company-type-investor {
        color: $colorMoneySupplier;
    }
    &.company-type-money-supplier {
        color: $colorMoneySupplier;
    }
    &.company-type-intermediary {
        color: $colorIntermediary;
    }
}
