$baseSpacing:                6px;

$marginXSmall:               $baseSpacing;
$marginSmall:                $baseSpacing * 2;
$marginNormal:               $baseSpacing * 3;
$marginLarge:                $baseSpacing * 4;
$marginLarger:               $baseSpacing * 5;
$marginXLarge:               $baseSpacing * 6;
$marginXXLarge:              64px;
$marginContents:             34px;

$topBarHeight:               80px;

$colorText:                  #000000;
$colorTextWhite:             #FFFFFF;
$colorTextGray:              #9C9FAF;
$colorPrimary:               #153455;
$colorGray:                  #AFB8C7;
$colorLightGray:             #A5ABB6;
$colorVeryLightGray:         #EAEAEA;
$colorWarning:               #FF7A00;
$colorDanger:                #ED143D;
$colorSuccess:               #00A000;
$backgroundDarkBlue:         #153455;
$backgroundDarkerBlue:       #112C4A;
$backgroundLightGray:        #D9DAE1;
$backgroundLighter:          #FFFFFF;
$backgroundDarker:           #FAFBFD;
$error:                      #ED143D;
$colorClientCompany:         #9190CC;
$colorClientPersonal:        #9CCEEA;
$colorIntermediary:          #8FA1FF;
$colorMoneySupplier:         #DABC70;
$backgroundClientCompany:    #EFEEF4;
$backgroundClientPersonal:   #E9EEF3;
$backgroundIntermediary:     #ECF1FB;
$backgroundMoneySupplier:    #F4F2F0;
;


$breakpointLegacy:           0;
$breakpointXs:               400;
$breakpointSm:               600;
$breakpointMd:               900;
$breakpointLg:               1200;
$breakpointXl:               1536;

:export {
    text: $colorText;
    primary: $colorPrimary;
    gray: $colorGray;
    lightGray: $colorLightGray;
    red: $error;
    xs: $breakpointXs;
    sm: $breakpointSm;
    md: $breakpointMd;
    lg: $breakpointLg;
    xl: $breakpointXl;
}
