@import '../../variables';

.TaskItem {
    cursor: pointer;

    .TaskItem-Wrapper {
        padding: 16px 0 2px 0;
    }

    .HorizontalDivider {
        margin: 24px 0 0 0;
    }

    .TaskItem-creator, .TaskItem-timestamp {
        color: $colorTextGray;
    }

    .TaskItem-creator {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .executor-badge {
            margin-right: $marginXSmall;
        }
    }

    .TaskItem-text {
        margin-top: $marginXSmall;
        margin-bottom: $marginXSmall;
        max-width: 250px;

        @media screen and (max-width: $breakpointLg+px) {
            max-width: 90%;
        }

        .TaskItem-text-title,
        .TaskItem-text-content {
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }
    }
}