@import "../../variables";

.EditField {
    padding: $marginSmall;
    &.bottom-spacing {
        padding-top: $marginSmall;
        padding-bottom: $marginXLarge;
    }

    .EditField-name {
        color: $colorGray;
    }

    .read-only-with-icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    @media screen and (max-width: $breakpointMd+px) {
        padding: $marginXSmall;
        &.bottom-spacing {
            padding-top: $marginXSmall;
            padding-bottom: $marginXSmall;
        }
    }
}