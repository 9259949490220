@import "../../variables";

.ChangeProjectStatus {


    .ChangeProjectStatus-icon {
        height: $marginXLarge;
        width: $marginXLarge;
    }
    .ChangeProjectStatus-content {
        padding: $marginXLarge;

        .ChangeProjectStatus-title {
            font-size: 28px;
            line-height: $marginXLarge;
            margin-bottom: $marginNormal;
        }

        .status-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: $marginSmall;

            .status-cell {
                &:first-child {
                    min-width: $marginNormal;
                }
                &:nth-child(2) {
                    min-width: $marginXLarge;
                }
            }
        }

        .ChangeProjectStatus-internal-name {
            .EditField {
                padding: 0;
            }
        }

        .ChangeProjectStatus-internal-name, .ChangeProjectStatus-notify-client {
            margin-top: $marginNormal;
        }

        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: flex-start;

        gap: $marginNormal;
    }
}