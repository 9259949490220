@import "../../variables";

.CardUserStatistics {

    background-color: $backgroundLighter;
    padding: $marginLarge;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;

    .CardUserStatistics-boxes {
        line-height: 1;
        margin-top: $marginLarge;

        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 150px;


        .CardUserStatistics-box {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: $marginLarge $marginLarge*2 auto;

            padding: 0 $marginLarge;
            border-right: 1px solid $colorLightGray;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
                border-right: none;
            }

            .value {
                font-size: 64px;
                font-weight: 500;
            }
        }

        @media screen and (max-width: $breakpointMd+px) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr 1fr 1fr;
            .title {
                font-size: 16px;
                margin: 0;
            }
            .CardUserStatistics-box {
                grid-template-columns: $marginLarge $marginLarge*7 1fr;
                grid-template-rows: 1fr;
                padding: $marginXSmall 0;
                border-right: none;

                .value {
                    font-size: 24px;
                }
            }
        }
    }

    .CardUserStatistics-range-selector {
        font-size: 14px;
        .toggle-button {
            margin: 0;
            padding: 0;
            font-size: 14px;
            font-weight: 400;
        }
        .hidden-section {
            margin-top: $marginSmall;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            color: gray;
        }
        .CardUserStatistics-DatePicker {
            max-width: 120px;
            margin-left: $marginXSmall;
            margin-right: $marginXSmall;
            > * {
                font-size: 14px;
            }
        }
    }

    @media screen and (max-width: $breakpointSm+px) {
        .CardUserStatistics-range-selector .hidden-section {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }
    }

    .rotate90deg {
        transform: rotate(90deg);
    }
}