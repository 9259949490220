@import "../../variables";

.MergeProjectsModal {

    .modal-title {
        margin-bottom: $marginLarge;
    }
    .modal-content {
        margin-bottom: $marginLarge;
        &:last-child {
            margin-bottom: 0;
        }
        align-self: stretch;
    }

    .warning {
        color: $colorDanger;
    }

    .decision-title {
        margin-bottom: $marginLarge;
    }

    .project-selection {
        margin-top: $marginSmall;
        margin-bottom: $marginLarge;
        display: flex;
        flex-direction: row;
        .projects-dropdown-autocomplete {
            flex: 1;
        }
        .merge-action {
        }
    }
}