@import "../../variables";

.ModalDialog {
    .ModalDialog-wrapper {
        position: absolute;
        top: 10vh;
        left: 15vw;
        right: 15vw;
        width: 75vw;
        min-height: 68vh;
        overflow-x: auto;

        &:focus {
            outline: none !important;
        }

        .ModalDialog-inner {
            background-color: $backgroundDarker;
            border: 1px solid $colorPrimary;
            padding: $marginNormal $marginNormal $marginXLarge;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .ModalDialog-close {
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
            }
        }
        .ModalDialog-bottomSpacer {
            height: 10vh;
        }
    }
}