@import "variables";
@import "fonts";

//body {
//  margin: 0;
//  padding: 0;
//  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//    sans-serif;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//}

body {
  margin: 0;
  padding: 0;
}
#root {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
}