.ShortenedText {
    width: 100%;
    height: 1.4em;
    display: inline-block;
    overflow: hidden;
    position: relative;
}

.noBreak {
    position: absolute;
    white-space: nowrap;
}

.dots {
    float: right;
}

.clearFix:after {
    display: table-cell;
    content: "";
    clear: both;
}

