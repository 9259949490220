@import "../../variables";

.UserDetails {
    text-align: center;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    .UserDetails-child {
        flex: 1;
        margin: 0 0 $marginSmall $marginSmall;
        &:first-child {
            .MuiTypography-h5 {
                line-height: 0;
            }
        }
        &:last-child {
            margin-right: $marginSmall;
        }
    }

    @media screen and (max-width: $breakpointLg+px) {
        flex-direction: column;
        align-items: stretch;
        .UserDetails-child {
            flex: unset;
            margin-right: $marginSmall;
        }
    }

    @media screen and (max-width: $breakpointSm+px) {
        .UserDetails-child {
            margin: $marginXLarge 0 0 0;
            &:last-child {
                margin-bottom: $marginXLarge;
            }
        }
    }
}