@import "../../variables";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px $backgroundLighter inset !important;
}

.AddingNote {
  a {
    text-decoration: none;
    color: $colorPrimary;
  }

  .Title {
    font-size: 28px;
    font-weight: 500;
    text-align: left;
    color: #000000;
    margin-bottom: 44px;
  }

  .CreateButton {
      font-size: 18px;
      width: 400px;

      @media screen and (max-width: 460px) {
        width: 120px;
      }
  }

  .EditField {
    padding: 0;

    &-name {
      margin-bottom: 8px;
      color: #9C9FAF;
    }

    .MuiOutlinedInput-root {
      height: 400px;

      .MuiOutlinedInput-input {
        position: absolute;
        top: 5px;
      }
    }
  }

  .FormField {
    width: 400px;
    margin-bottom: 20px;
  }

  .ErrorMessage {
    width: 46%;
    font-size: 14px;
    margin-top: 10px;
  }
}

