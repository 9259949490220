@import "../../variables";

.CompanyDetails {

    .section-contacts {
        .IndexPageHeader {
            margin: 0;
        }
        .header-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 $marginContents;
        }
        .IndexTable > div {
            overflow: hidden;
        }
        .MuiTablePagination-toolbar, .MuiTableCell-footer {
            display: none;
        }
    }

    .section-notes {
        .notes {
            padding: $marginContents;
            padding-top: 0;
        }
    }

    .section-tasks {
        .tasks {
            padding: $marginContents;
            padding-top: 0;
        }
    }

    .section-attachments {
        .attachments {
            padding: $marginContents;
            padding-top: 0;
        }
    }

    .section-projects {
        padding: $marginContents;
        padding-top: 0;

        .projects {

            @media screen and (max-width: $breakpointLg+px){
                max-width: unset;
            }

            .filters {
                border-bottom: 1px solid #EAEAEA;
                display: flex;

                .filter {
                    margin-right: 32px;
                    font-size: 18px;
                    line-height: 22px;
                    letter-spacing: 0.2px;
                    color: #AFB8C7;
                    padding-bottom: 4px;
                    cursor: pointer;

                    &.filter-active {
                        color: #4075FF;
                        border-bottom: 2px solid #4075FF;
                    }
                }
            }

            .projects-grid {
                margin-top: 24px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 24px;

            }
        }

    }

}

.SingleEntityLayout .SingleEntityLayout-right {
    &.type-money-supplier {
        background-color: $backgroundMoneySupplier;
    }
    &.type-intermediary {
        background-color: $backgroundIntermediary;
    }
    &.type-client {
        background-color: $backgroundClientCompany;
        &.type-is-personal {
            background-color: $backgroundClientPersonal;
        }
    }
}
