@use "../../../../variables" as var;

.PersonalFilter {
    padding: var.$marginSmall;
    max-width: 220px;
    width: 220px;
}
.input {

}
