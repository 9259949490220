@use "../../variables" as var;


.UsersPage {

}

.pageHeader {
    margin-top: var.$marginLarger;
}

.controlBar {
    margin: var.$marginNormal;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: stretch;

    .left {
        flex: 1;
    }

    @media screen and (max-width: var.$breakpointLg+px) {
        flex-direction: column-reverse;
        align-items: flex-end;
        .left {
            align-self: flex-start;
        }
    }
}
