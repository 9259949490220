@use "../../variables" as var;

.ProjectDetailsPage {

}

.gridView {
    margin-top: var.$marginLarge;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: var.$marginLarge;

    @media screen and (max-width: var.$breakpointXl+px) {
        grid-template-columns: 1fr;
    }
}

.inner {
    padding: var.$marginLarge;
}
