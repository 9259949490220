@import "../../variables";

.NotificationItem {

    background-color: $backgroundLighter;
    padding: $marginNormal $marginLarge;

    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;

    .NotificationItem-text {
        &.unseen {
            cursor: pointer;
        }

        .NotificationItem-red-dot {
            color: $colorDanger;
            margin-right: 0.5em;
        }
    }

    .NotificationItem-navigate {
        height: 100%;
        align-items: flex-start;
    }
}