@import "../../variables";

.DefaultLayout {

    position: absolute;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;

    .DefaultLayout-right {
        flex: 1;
        background-color: $backgroundDarker;

        .DefaultLayout-navbar {
            background-color: $backgroundLighter;
        }
    }

    @media screen and (max-width: $breakpointMd+px) {
        flex-direction: column;
    }
}