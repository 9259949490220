@import "../../variables";

.ProjectSummaryBrick {

    background: #fff;
    padding: 24px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
    cursor: pointer;

    display: flex;
    flex-direction: column;

    .flex-spacer {
        flex: 1;
    }

    .ProjectSummaryBrick-section {
        margin-bottom: $marginSmall;
        line-height: 1;
        &:last-child {
            margin-bottom: unset;
        }
    }

    .project-header, .project-primary-user {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: $marginSmall;

    }

    .project-name-status {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: $marginXSmall;
    }
}