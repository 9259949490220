.Settings-wrapper {
    padding: 64px 34px; 

    .change-password-form {      
        input::placeholder {
          color: #9C9FAF;
          opacity: 1;
        }
      
        .title {
          font-size: 36px;
          font-weight: 500;
          text-align: left;
          color: #000000;
          margin-bottom: 8px;
        }
      
        .field-title {
          font-size: 15px;
          font-weight: 400;
          text-align: left;
          color: #9c9faf;
          margin-top: 28px;
    
          p {
              margin: 0;
          }
        }
      
        .forgotten-password {
          line-height: 1.75;
          a {
            font-size: 16px;
            font-weight: 500;
            margin: auto;
            text-decoration: none;
            vertical-align: middle;
            color: #000000;
          }
        }

        .ErrorMessage {
            padding: 5px 0 0;
            font-size: 14px;
        }
      }
      
      .infoIcon {
        display: block;
        margin: 48px auto 0;
      }
}
  