@use "../../../../../variables" as var;

.CompanyEmployee {
    display: grid;
    grid-template-columns: 130px 130px 200px 20px;
}

.bold {
    font-weight: bold !important;
}

.spaced {
    margin-left: var.$marginSmall;
}
