@use "../../../../variables" as var;

.filterWrapper {
}

.inputText {

}

.filter {
    width: 100%;
}

.noPadding {
    padding: 0;
}

.inputHeight {
    height: 55px;
}

.PersonalFilter {
    padding: var.$marginSmall;
    max-width: 220px;
    width: 220px;
}