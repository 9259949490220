@import "../../variables";

.AddProjectModal {

    .ModalDialog-inner {
        min-height: 68vh;
        justify-content: flex-start !important;
    }

    .FormField {
        width: 400px;

        .CreatedDate {
            color: #9C9FAF;
            font-size: 13px;
            margin: 4px 0;
        }

        .MuiFormControlLabel-label {
            font-size: 16px;
        }
    }

    .Title {
        font-size: 28px;
        font-weight: 500;
        text-align: left;
        color: #000000;
        margin-bottom: 44px;
    }

    .Subtitle {
        width: 400px;
        font-size: 20px;
        font-weight: 500;
        margin-top: $marginLarge;
        margin-bottom: $marginXSmall;
        text-align: left;
    }

    .CreateButton {
        font-size: 18px;
        width: 400px;

        @media screen and (max-width: 460px) {
            width: 120px;
        }
    }

    .AddProjectModal-content {
        width: 400px;

        .EditField {
            padding: 12px 0;
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 30px $backgroundLighter inset !important;
    }
}