@import "../../variables";

.Error404Page {
    max-width: 600px;
    text-align: center;

    .Error404Page-title {
        margin-bottom: $marginXXLarge;
    }

    .Error404Page-actions {
        margin-top: $marginXXLarge;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}