@use "../../variables" as var;

.AllTasksModal {

    .ModalDialog-inner {
        background-color: var.$backgroundLighter;
    }

    .modalTitle {
        text-align: center;
    }

    .modalContent {
        width: 90%;

        .MuiCard-root {
            cursor: pointer;
        }
    }

    .modalFooter {
        text-align: left;
        align-self: stretch;
    }
}
