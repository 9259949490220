@import "../../variables";

.UnseenNotificationsList {

    .UnseenNotificationsList-item {
        margin-bottom: $marginLarge;
    }

    .UnseenNotificationsList-empty {
        padding: $marginLarge;
    }
}