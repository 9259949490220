@import "../../variables";

.IndexTable {
    margin: $marginLarge $marginContents;

    tbody tr {
        cursor: pointer;
    }

    tbody tr td:first-child {
        font-weight: 500;
    }

    .delayed {
        .index-3 {
            color:#ED143D;
        }
    }
}