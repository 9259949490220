@import "../../variables";

.LoginForm {
  position: relative;
  z-index: 2222;
  margin: 0;
  max-width: 300px;
  color: $colorTextWhite;

  @media screen and (min-width: $breakpointXs+px) {
    max-width: 600px;
    min-width: 400px;
    margin: 0 auto; 
  }


  .LoginForm-title {
    font-size: 36px;
    text-transform: uppercase;
  }
  .LoginForm-field {
    margin-top: $marginLarge;
    .LoginForm-field-title {
      margin-bottom: $marginXSmall;
      color: $colorTextWhite;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px $backgroundLighter inset !important;
  }

  .input-error {
    input:-webkit-autofill {
      -webkit-text-fill-color: $error;
    }
  }

  .forgotten-password {
    margin: auto 0;
    font-size: 16px;
    font-weight: 500;
    color: $colorTextWhite;
    a {
      text-decoration: none;
      color: $colorTextWhite;
    }
  }

  .on-dark-background {
    background-color: $backgroundLighter;
  }
}
