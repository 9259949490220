.wide {
    min-width: 400px;
}
.spacer {
    flex: 1;
}
.actions {
    width: 100%;
    display: flex;
}
