@import "../../variables";

.RecentActivityViewer {

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    // > div:first-child > div {
    //     padding: 0 0 $marginNormal 0;
    // }

    .BasicCard-header {
        padding: $marginNormal $marginNormal 0;
    }
    .BasicCard-content {
        padding: 0 $marginNormal $marginNormal;
    }
}