@import "../../variables";

.CardCompanyPurge {
    margin: $marginSmall;
    .BasicCard-header {
        margin-bottom: $marginLarge;
    }
}

.CardCompanyPurge-confirmation-modal {
    .error-feedback {
        color: $colorDanger;
    }
}