@import "../../variables";

.IndexPageHeader {
    margin: $marginXXLarge $marginContents $marginLarge;

    .IndexPageHeader-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
}