@mixin font-face( $font-name, $font-path, $font-weight: 400, $font-style: normal) {
    @font-face {
        font-family: $font-name;
        font-style : $font-style;
        font-weight: $font-weight;
        src: url('#{$font-path}.ttf') format('truetype');
    }
}

@include font-face("Barlow", "./assets/fonts/Barlow/Barlow-Thin", 100, normal);
@include font-face("Barlow", "./assets/fonts/Barlow/Barlow-ExtraLight", 200, normal);
@include font-face("Barlow", "./assets/fonts/Barlow/Barlow-Light", 300, normal);
@include font-face("Barlow", "./assets/fonts/Barlow/Barlow-Regular", 400, normal);
@include font-face("Barlow", "./assets/fonts/Barlow/Barlow-Medium", 500, normal);
@include font-face("Barlow", "./assets/fonts/Barlow/Barlow-SemiBold", 600, normal);
@include font-face("Barlow", "./assets/fonts/Barlow/Barlow-Bold", 700, normal);
@include font-face("Barlow", "./assets/fonts/Barlow/Barlow-ExtraBold", 800, normal);
@include font-face("Barlow", "./assets/fonts/Barlow/Barlow-Black", 900, normal);
