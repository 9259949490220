@import "../../variables";

.LeftMenuItem {

    display: flex;
    flex-direction: row;
    align-items: center;

    text-decoration: none;
    cursor: pointer;

    padding: $marginNormal;
    border-left: 3px solid transparent;
    min-width: 200px;

    user-select: none;

    &:visited {
        color: $colorGray;
    }

    .LeftMenuItem-icon {
        width: $marginXLarge;
        color: $backgroundLightGray;
    }
    .LeftMenuItem-text {
        flex: 1;
        text-align: left;
        font-weight: 400;
        font-size: 16px;
        &:visited {
            color: $colorGray;
        }
    }

    &.active {
        background-color: $backgroundDarker;
        border-left: 3px solid $colorPrimary;
        .LeftMenuItem-text {
            color: $colorPrimary;
            &:visited {
                color: $colorPrimary;
            }
        }

        .LeftMenuItem-icon {
            width: $marginXLarge;
            color: $colorPrimary;
        }
    }

    .unseen-count {
        position: absolute;
        font-size: 0.75em;
        padding: 0.25em;
        font-weight: 500;
        height: 2em;
        width: 2em;
        line-height: 1.5em;
        text-align: center;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background-color: $colorDanger;
        color: $backgroundLighter;
    }

    @media screen and (max-width: $breakpointMd+px) {
        padding: $marginXSmall;
        min-width: unset;
        .unseen-count {
            position: relative;
        }
    }
}