@use "../../../variables" as var;

.FiltersBlock {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @media screen and (max-width: var.$breakpointLg+px) {
        grid-template-columns: 1fr 1fr;
    }
}

.ActionsBlock {

}
