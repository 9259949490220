@use "../../../variables" as var;

.FiltersBlock {
    display: grid;
    grid-template-columns: 310px 310px 250px;
    gap: var.$marginXSmall;

    @media screen and (max-width: var.$breakpointXl+px) {
        grid-template-columns: 310px 310px;
    }

    @media screen and (max-width: var.$breakpointSm+px) {
        grid-template-columns: 1fr;
    }
}
