@import "../../variables";

.AddContactModal {
    .InviteUserModal-contents-wrapper {
        max-width: 400px;
    }
    .InviteUserModal-title {
        text-align: center;
        margin-bottom: $marginXLarge;
    }
    .button-send-invite {
        margin-top: $marginLarge;
        margin-left: $marginSmall;
        margin-right: $marginSmall;
        width: (400px - ($marginSmall * 2));
    }
    .errorMessage {
        padding: $marginSmall;
        text-align: center;
    }
}