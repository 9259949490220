@use "../../../variables" as var;

.ProjectLinkedCompanies {
    display: flex;
    margin-top: var.$marginLarge;
}

.card {
    width: 100%;
    background-color: lighten(var.$colorVeryLightGray,4%) !important;

    &.isEditing {
        background-color: unset !important;
    }
}

.cardActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.relationType {
    padding: var.$marginNormal 0;
    border-bottom: 1px solid var.$colorLightGray;
    &:first-child {
        padding-top: 0;
    }
    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }
}
