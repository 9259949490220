@import "../../variables";

.AddCompanyModal {

    .ModalDialog-inner {
        min-height: 68vh;
        justify-content: flex-start !important;
    }

    .AddCompanyModal-title {
        font-size: 28px;
        font-weight: 500;
        text-align: left;
        color: #000000;
        margin-bottom: 44px;
    }

    .AddCompanyModal-buttons {
        margin-top: $marginLarge;

        .CreateButton {
            font-size: 18px;
            width: 400px;

            @media screen and (max-width: $breakpointSm+px) {
                width: 120px;
            }
        }
    }

    .AddCompanyModal-fields {
        width: 400px;

        @media screen and (max-width: $breakpointSm+px) {
            width: unset;
        }

        .EditField {
            padding: 12px 0;
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 30px $backgroundLighter inset !important;
    }
}