@use "../../../../variables" as var;

.Search {

    .SearchInput {
        font-size: 14px;
        font-weight: 400;
        background-color: #FFFFFF;
    }

    @media screen and (max-width: var.$breakpointSm+px) {
        width: unset;
        max-width: 200px;
    }
}
