@import "../../variables";

.UserData {

  .merge-button {
    font-size: 14px;
    margin-left: $marginSmall;
    margin-bottom: $marginLarge;
  }

  .name {
    margin: 48px 0 32px;
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: 0.2px;
    color: #000000;
  }
  
  .label {
    margin-top: 24px;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.2px;
    color: #AFB8C7;
  }

  .value {
    margin-top: 4px;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.2px;
    color: #000000;
  }
}