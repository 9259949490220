@use "../../../../variables" as var;

.BreadcrumbNavigation {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    margin-bottom: var.$marginXLarge;
    cursor: pointer;
}

.arrowBack {
    width: 1.8em;
    margin-right: 1em;
    background-image: url('../../../../assets/arrow_back.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
