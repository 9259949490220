@import "../../variables";

.password-changed {
  margin: 0 auto;
  max-width: 500px;

  @media screen and (max-width: 460px) {
    margin: 20px;
  }

  .title {
    font-size: 36px;
    font-weight: 500;
    text-align: left;
    color: $colorTextWhite;
    margin-bottom: 8px;
  }

  .content {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    text-align: left;
    margin-top: 16px;
  }

  .link {
    text-decoration: none;
    color: #ffffff;

    ::first-letter {
      text-transform: capitalize;
    }
  }
  .btn {
    padding: 0.5em 2em;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: $colorPrimary;
    transition: background-color 0.5s;
    &:hover {
      background-color: lighten($colorPrimary, 30%);
    }
  }
}